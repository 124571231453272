<template>
  <div>
    <div class="contain1">
      <div class="contain1_top">
        <div class="h1">订单信息</div>
        <div class="action">
          <div @click="goList()">
            <img src="@/assets/image/icon83.png" alt="" />订单列表
          </div>
        </div>
      </div>
      <div class="contain1_line"></div>
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon84.png" alt="" />
                文档名称
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #23dc5a">
                {{ document.title }}
              </div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon94.png" alt="" />
                订单号
              </div>
            </template>
            <template>
              <div class="info_style1">{{ order.order_no }}</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon86.png" alt="" />
                下单时间
              </div>
            </template>
            <template>
              <div class="info_style1">{{ order.create_time }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon86.png" alt="" />
                关闭时间
              </div>
            </template>
            <template>
              <div class="info_style1">
                {{ order.pay_expire_time | formatDate }}
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon87.png" alt="" />
                订单状态
              </div>
            </template>
            <template>
              <div class="info_style2">
                <div class="info_style2_3" v-if="order.status == 1">待支付</div>
                <div class="info_style2_2" v-if="order.status == 2">已支付</div>
                <div class="info_style2_1" v-if="order.status == 3">已关闭</div>
              </div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon88.png" alt="" />
                购买数量
              </div>
            </template>
            <template>
              <div class="info_style1">{{ order.count }}</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon89.png" alt="" />
                商品单价
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #ff0000">
                {{ order.price_old||0 }}元
              </div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon95.png" alt="" />
                实付金额
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #ff0000">
                {{ order.price||0 }}元
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="contain2">
      <div class="h1">商品信息</div>
      <div class="contain2_line"></div>
      <div>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon88.png" alt="" />
                文档
              </div>
            </template>
            <template>
              <div class="info_style1" style="color: #23dc5a">
                {{ document.title }}
              </div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon96.png" alt="" />
                评分
              </div>
            </template>
            <template>
              <div class="info_style1">
                <el-rate v-model="rate" disabled score-template="5"> </el-rate>
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon97.png" alt="" />
                大小
              </div>
            </template>
            <template>
              <div class="info_style1">{{ formatBytes(document.size) }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon98.png" alt="" />
                下载数
              </div>
            </template>
            <template>
              <div class="info_style1">{{ document.download_count||0 }}</div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="2" border>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon38.png" alt="" />
                浏览
              </div>
            </template>
            <template>
              <div class="info_style1">{{ document.view_count }}</div>
            </template>
          </el-descriptions-item>
          <el-descriptions-item
            labelClassName="label1"
            contentClassName="contentClassName1"
          >
            <template slot="label">
              <div class="label_title">
                <img src="@/assets/image/icon99.png" alt="" />
                收藏
              </div>
            </template>
            <template>
              <div class="info_style1">
                {{ document.collection_count || 0 }}
              </div>
            </template>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>
      
<script>
import { formatBytes } from "/utils/utils";
export default {
  name: "vipOrder",
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rate: 0,
    };
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  created() {
    // this.document = {
    //   ...this.document,
    //   score: this.document.score / 100,
    // };
  },
  mounted() {
    document.body.style.backgroundColor = "#f6f7f8";
    setTimeout(() => {
      this.rate = this.document.score / 100;
    },1000);
  },
  methods: {
    formatBytes,
    goList() {
      this.$router.push({
        path: "/personalCenter",
        query: { type: 10 },
      });
    },
  },
};
</script>
      
    <style lang="scss" scoped>
.contain1 {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  .contain1_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .h1 {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
    }
    .action {
      display: flex;
      align-items: center;
      cursor: pointer;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        letter-spacing: 0.9px;
        margin-left: 20px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }
      }
    }
  }
  .contain1_line {
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }
}
.contain2 {
  width: 1400px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto 40px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    letter-spacing: 0.9px;
  }
  .contain2_line {
    height: 1px;
    background: #f4f4f4;
    margin: 20px 0;
  }
}
</style>
      <style lang="scss">
.label1 {
  width: 287px;
  height: 85px;
}
.contentClassName1 {
  width: 394px;
}
.label2 {
  width: 287px;
  height: 85px;
}
.contentClassName2 {
  width: 1074px;
}
.label_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #999999;
  letter-spacing: 0.7px;
  margin-left: 20px;
  img {
    width: 25px;
    height: 25px;
    margin-right: 12px;
  }
}
.info_style1 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.7px;
  margin-left: 20px;
}
.info_style2 {
  margin-left: 20px;
  .info_style2_1 {
    width: 80px;
    height: 39px;
    background: #e2e2e2;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #999999;
    line-height: 39px;
  }
  .info_style2_2 {
    width: 80px;
    height: 39px;
    background: #edfff2;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #23dc5a;
    line-height: 39px;
  }
  .info_style2_3 {
    width: 80px;
    height: 39px;
    background: #ffeded;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #e62323;
    line-height: 39px;
  }
}
</style>